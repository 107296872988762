import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Alert, Button, Card, Row, Col } from 'react-bootstrap';
import { DataManager } from '../core/data-manager';
import { LoginManager } from '../core/login-manager';
import { LanguageProvider } from '../core/language-provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function Dashboard() {

    const [errors, setErrors] = useState('');
    const [data, setData] = useState(null);

    useEffect(() => {
        async function init() {
            var userInfo = LoginManager.getUserInfo()
            
        }
        init()

    }, []);



    return (
        <>
            <Container fluid className='mt-5'>

                <Row>
                    <Col xs={12} md={4} lg={3}>
                        <Card bg={'danger'} className='text-light mb-3'>
                            <Card.Body>
                                <Card.Title>Ordini cliente <FontAwesomeIcon icon="users" className='ms-1' /></Card.Title>
                                Gestisci tutti gli ordini cliente.
                            </Card.Body>
                            <Card.Footer>
                                <Link to="/page/DashboardCustomerOrder">
                                    <Button variant='light'>Vai al pannello</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col xs={12} md={4} lg={3}>
                        <Card bg={'primary'} className='text-light mb-3'>
                            <Card.Body>
                                <Card.Title>Ordini fornitore <FontAwesomeIcon icon="receipt" className='ms-1' /></Card.Title>
                                Gestisci tutti gli ordini fornitore.
                            </Card.Body>
                            <Card.Footer>
                                <Link to="/page/DashboardSupplierOrder">
                                    <Button variant='light'>Vai al pannello</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col xs={12} md={4} lg={3}>
                        <Card bg={'success'} className='text-light mb-3'>
                            <Card.Body>
                                <Card.Title>Consegne <FontAwesomeIcon icon="truck" className='ms-1' /></Card.Title>
                                Gestisci le tue consegne.
                            </Card.Body>
                            <Card.Footer>
                                <Link to="/page/DashboardShipping">
                                    <Button variant='light'>Vai al pannello</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col xs={12} md={4} lg={3}>
                        <Card bg={'info'} className='text-light mb-3'>
                            <Card.Body>
                                <Card.Title>Attività <FontAwesomeIcon icon="list" className='ms-1' /></Card.Title>
                                Visualizza tutte le attività.
                            </Card.Body>
                            <Card.Footer>
                                <Link to="/entity/activity">
                                    <Button variant='light'>Vai al pannello</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </>
    )
};