import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Col, Modal, Row, Container } from 'react-bootstrap';
import { DataManager } from '../../core/data-manager';
import { ApiManager } from '../../core/api-manager';
import { LoginManager } from '../../core/login-manager';
import { LanguageProvider } from '../../core/language-provider';
import ModalList from '../../components/Modals/ModalList';
import ModalEntityViewer from '../../components/Modals/ModalEntityViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';



export default function DataGridLastTdHook({ item, props, entity = null }) {

    const navigate = useNavigate()


    useEffect(() => {
        console.warn(item)
    }, []);

    //customer
    const [customerHistoryEntity, setCustomerHistoryEntity] = useState(null)
    const [customerHistorySearchObj, setCustomerHistorySearchObj] = useState(null)
    const [statusCustomerModal, setStatusCustomerModal] = useState(false)
    function showCustomerOffer(item) {
        let query = ''
        let fields = []
        let constraints = [{ "fieldName": "idCustomer", type: "value", value: item.id }]

        setCustomerHistorySearchObj({
            query: query,
            fields: fields,
            constraints: constraints
        })

        setCustomerHistoryEntity('customer_offer')
    }
    function showCustomerOrder(item) {
        let query = ''
        let fields = []
        let constraints = [{ "fieldName": "idCustomer", type: "value", value: item.id }]

        setCustomerHistorySearchObj({
            query: query,
            fields: fields,
            constraints: constraints
        })

        setCustomerHistoryEntity('customer_order')
        setStatusCustomerModal(true)
    }
    function closeCustomerModal() {
        setCustomerHistoryEntity(null)
        setStatusCustomerModal(false)
    }

    function newCustomerOffer(item) {
        navigate('/entity/customer_offer/add', {
            state: {
                body: {
                    idCustomer: item
                }
            }
        })
    }
    function newCustomerOrder(item) {
        navigate('/entity/customer_order/add', {
            state: {
                body: {
                    idCustomer: item
                }
            }
        })
    }



    //customer offer
    function previewCustomerOffer(item) {
        navigate('/page/CustomerOfferPreview?id=' + item.id)
    }
    const [statusModalEntityViewerCustomerOffer, setStatusModalEntityViewerCustomerOffer] = useState(false)
    function closeModalEntityViewerCustomerOffer() {
        setStatusModalEntityViewerCustomerOffer(false)
    }
    function openModalEntityViewerCustomerOffer() {
        setStatusModalEntityViewerCustomerOffer(true)
    }

    const [statusModalEntityViewerCustomerOrder, setStatusModalEntityViewerCustomerOrder] = useState(false)
    function closeModalEntityViewerCustomerOrder() {
        setStatusModalEntityViewerCustomerOrder(false)
    }
    function openModalEntityViewerCustomerOrder() {
        setStatusModalEntityViewerCustomerOrder(true)
    }

    const [statusModalEntityViewerSupplierOrder, setStatusModalEntityViewerSupplierOrder] = useState(false)
    function closeModalEntityViewerSupplierOrder() {
        setStatusModalEntityViewerSupplierOrder(false)
    }
    function openModalEntityViewerSupplierOrder() {
        setStatusModalEntityViewerSupplierOrder(true)
    }



    //customer order
    function previewCustomerOrder(item) {
        navigate('/page/CustomerOrderPreview?id=' + item.id)
    }
    function ddtCustomerOrder(item) {
        navigate('/page/CustomerDdtPreview?id=' + item.id)
    }



    //supplier order
    function previewSupplierOrder(item) {
        navigate('/page/SupplierOrderPreview?id=' + item.id)
    }


    async function customerOfferConvertInOrder() {
        var userInfo = LoginManager.getUserInfo();
        console.warn('item', item)
        const id = toast.loading("Conversione preventivo...")

        if (item.status && item.status.value === "10_CONFIRMED") {
            toast.update(id, { render: "Questo preventivo è già stato convertito in ordine.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
        }
        else {
            var bodyCustomer = {
                type: { value: '10_CONVERTED' }
            }
            var response1 = await DataManager.updateItem('customer', bodyCustomer, item.idCustomer)


            var body = {
                status: { value: '10_CONFIRMED' }
            }
            var response = await DataManager.updateItem('customer_offer', body, item.id)
            console.warn(response)
            if (response.success === 1) {
                var sorting = {
                    'field': 'id',
                    'order': 'desc'
                }
                var constraints = [{ "fieldName": "levelUser", type: "value", value: 1 }]
                var resNotifiche = await DataManager.searchItems('gyv_notification', 1, 50, sorting, '', [], constraints);
                console.log(resNotifiche);
                if (resNotifiche.success === 1 && resNotifiche.body.length > 0) {
                    for (let i = 0; i < resNotifiche.body.length; i++) {
                        if (resNotifiche.body[i].notificationAll === 1 && resNotifiche.body[i].notificationOffer === 1) {
                            var bodyEmail = {
                                emailTo: resNotifiche.body[i].emailUser,
                                riferimento: item.internalCode,
                                idOrder: item.id,
                                entity: 'offer',
                                stato: 'CONVERTITO IN ORDINE'
                            }
                            var responseEmail = await ApiManager.sendAuthenticatedRequest('/custom/send_mail_change_status', bodyEmail);
                            console.warn(responseEmail);
                        }
                    }
                }

                var sortingOffer = {
                    'field': 'id',
                    'order': 'desc'
                }
                var constraintsOffer = [{ "fieldName": "id", type: "value", value: item.id }]
                var resultOffer = await DataManager.searchItems('customer_offer', 1, 50, sortingOffer, '', [], constraintsOffer);
                if (resultOffer.success === 1) {

                    console.log(resultOffer);
                    toast.update(id, { render: "Preventivo confermato. Compila l'ordine con i campi mancanti. RICORDATI DI SALVARE L'ORDINE.", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
                    navigate('/entity/customer_order/add', {
                        state: {
                            body: {
                                idCustomer: { id: resultOffer.body[0].idCustomer.id, name: resultOffer.body[0].idCustomer.name },
                                idSalesAgent: resultOffer.body[0].idSalesAgent,
                                discount: resultOffer.body[0].discount,
                                notes: resultOffer.body[0].notes,
                                rows: resultOffer.body[0].rows,
                                files: resultOffer.body[0].files,
                                riferimento: resultOffer.body[0].riferimento,
                                status: { value: '0_PENDING' },
                                vat: resultOffer.body[0].vat,
                                idPaymentCondition: { id: resultOffer.body[0].idPaymentCondition.id, name: resultOffer.body[0].idPaymentCondition.name },
                                parking: resultOffer.body[0].parking,
                                parquet: resultOffer.body[0].parquet,
                                pedestrian_zone: resultOffer.body[0].pedestrian_zone,
                                hoist_elevator: resultOffer.body[0].hoist_elevator,
                                floor: resultOffer.body[0].floor,
                                no_traffic_zone: resultOffer.body[0].no_traffic_zone,
                                building: resultOffer.body[0].building,
                                zone: resultOffer.body[0].zone,
                                ladder_truck: resultOffer.body[0].ladder_truck,
                                elevator: resultOffer.body[0].elevator,
                                increase: resultOffer.body[0].increase,
                                discount: resultOffer.body[0].discount,
                                transportCost: resultOffer.body[0].transportCost,
                                deliveryDate: resultOffer.body[0].deliveryDate,
                                deliveryAddress: resultOffer.body[0].deliveryAddress
                            }
                        }
                    })

                }


                console.log(body);
            }
            else {
                if (response.message && response.message != '') {
                    toast.update(id, { render: response.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
                }
                else {
                    toast.update(id, { render: "Non è stato possibile convertire questo preventivo.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
                }
            }
        }
    }




    if (entity !== null) {
        return (
            <>
                {(() => {
                    switch (entity) {
                        case 'sample':
                            return (
                                <span>Test</span>
                            );
                            break;
                        case 'customer':
                            return (
                                <>

                                    <div className='d-flex flex-row'>
                                        <div className='d-flex flex-column'>
                                            <Button onClick={() => showCustomerOffer(item)} size="sm" variant="outline-secondary" className='m-1'>Preventivi</Button>
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <Button onClick={() => showCustomerOrder(item)} size="sm" variant="outline-secondary" className='m-1'>Ordini</Button>
                                        </div>

                                    </div>
                                    <div className='d-flex flex-row'>
                                        <Button onClick={() => newCustomerOffer(item)} size="sm" variant="outline-primary" className='m-1'>Crea preventivo</Button>
                                        <Button onClick={() => newCustomerOrder(item)} size="sm" variant="outline-primary" className='m-1'>Crea ordine</Button>
                                    </div>





                                    {statusCustomerModal && customerHistoryEntity ? <ModalList entity={customerHistoryEntity} searchObj={customerHistorySearchObj} status={statusCustomerModal} closeModal={closeCustomerModal} /> : <></>}

                                </>
                            );
                            break;
                        case 'gyv_customer_offer':
                            return (
                                <>
                                    {item.status.value === '0_PENDING' || item.status.value === '5_PRESALE' ?
                                        <Button className='m-1' variant='outline-success' size='sm' onClick={() => customerOfferConvertInOrder()}>Converti</Button>
                                        : <></>}
                                    <Button className='m-1' variant='outline-primary' size='sm' onClick={() => openModalEntityViewerCustomerOffer()}>Dettagli</Button>
                                    <Button onClick={() => previewCustomerOffer(item)} size="sm" variant="outline-secondary" className='m-1'><FontAwesomeIcon className='ms-1 me-1' icon='print'></FontAwesomeIcon></Button>

                                    {statusModalEntityViewerCustomerOffer !== false && (
                                        <ModalEntityViewer entity={'customer_offer'} item={item} status={statusModalEntityViewerCustomerOffer} closeModal={closeModalEntityViewerCustomerOffer} />
                                    )}
                                </>
                            );
                            break;
                        case 'gyv_customer_order':
                            return (
                                <>
                                    <Button onClick={() => ddtCustomerOrder(item)} size="sm" variant="outline-success" className='m-1'>DDT</Button>
                                    <Button className='m-1' variant='outline-primary' size='sm' onClick={() => openModalEntityViewerCustomerOrder()}>Dettagli</Button>
                                    <Button onClick={() => previewCustomerOrder(item)} size="sm" variant="outline-secondary" className='m-1'><FontAwesomeIcon className='ms-1 me-1' icon='print'></FontAwesomeIcon></Button>
                                    {statusModalEntityViewerCustomerOrder !== false && (
                                        <ModalEntityViewer entity={'customer_order'} item={item} status={statusModalEntityViewerCustomerOrder} closeModal={closeModalEntityViewerCustomerOrder} />
                                    )}

                                </>
                            );
                            break;
                        case 'gyv_supplier_order':
                            return (
                                <>
                                    <Button className='m-1' variant='outline-primary' size='sm' onClick={() => openModalEntityViewerSupplierOrder()}>Dettagli</Button>
                                    <Button onClick={() => previewSupplierOrder(item)} size="sm" variant="outline-secondary" className='m-1'><FontAwesomeIcon className='ms-1 me-1' icon='print'></FontAwesomeIcon></Button>
                                    {statusModalEntityViewerSupplierOrder !== false && (
                                        <ModalEntityViewer entity={'supplier_order'} item={item} status={statusModalEntityViewerSupplierOrder} closeModal={closeModalEntityViewerSupplierOrder} />
                                    )}
                                </>
                            );
                            break;
                        default:
                            return (
                                <></>
                            );
                            break;
                    }
                })()}
            </>
        )
    }
    else {
        return (
            <></>
        )
    }
};