import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
//import logoFooter from '../assets/logo_prelude.png';
import logoFooter from '../assets/logo.png';



export default function FooterHook() {

    useEffect(() => {
        
    }, []);

    const pp = 'https://www.preludeadv.it/azienda/privacy';



    return (
        <>
            <Container id="footer" fluid className='bg-light p-3'>
                <Container fluid className='text-end'>
                    <Image src={logoFooter} width='80' className='mb-2' />
                    <Link to={pp} target='_blank'><p>Privacy Policy</p></Link>
                    <p className='text-secondary small'>
                        <span className='small'>© Powered by Gruppo Yec</span>
                    </p>
                </Container>
            </Container>
        </>
    )
};