import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Table } from "react-bootstrap";
import { DataManager } from '../../../core/data-manager';
import { LanguageProvider } from '../../../core/language-provider';
import FieldPreview from '../../../components/Entity/FieldPreview';


export default function ModalEntityViewer({ item = null, entity = null, status = false, closeModal }) {
    
    const navigate = useNavigate()
    const excluded = ['deleted']
    const [data, setData] = useState(null)
    const [props, setProps] = useState(null)
    

    useEffect(() => {
        if(entity !== null && item !== null && status !== false) {
            init()
        }
    }, [status]);


    async function init() {
        console.log('init')
        var response = await DataManager.getItem(entity, item.id)
        console.log(response)
        if(response.success === 1) {
            setData(response.body[0])
            setProps(response.table)
        }
        else {

        }
    }




    if(status === true) {
        return (
            <>
                <Modal show={status} onHide={closeModal} size="xl" scrollable={true} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{LanguageProvider.get('defaults.details')} - {LanguageProvider.get('entity.'+entity)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {props !== null && data !== null ? (
                            <>
                                <Table striped bordered responsive className='small'>
                                    <tbody>
                                        {Object.keys(data).map((key, i) => {
                                            if(!excluded.includes(key)) {
                                                return (
                                                    <tr key={i}>
                                                        <th>
                                                            <span>{LanguageProvider.getLabel(entity, key)}</span>
                                                        </th>
                                                        <td>
                                                            <FieldPreview item={data} field={key} props={props} entity={entity} adv={true} />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </tbody>
                                </Table>
                            </>
                        ) : (
                            <></>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            {LanguageProvider.get('defaults.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
};