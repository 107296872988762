import { ApiManager } from '../../core/api-manager';
import { DataManager } from '../../core/data-manager';
import { settings } from '../../config/settings';
import { LanguageProvider } from '../../core/language-provider';
import { toast } from 'react-toastify';



export const actions = {
    DataGridRow: {
        sample: [
            {
                label: "Test",
                icon: "share",
                func: test
            }
        ],
        gyv_customer_order: [
            {
                label: "Anteprima",
                icon: "print",
                func: customerOrderPreview
            }
        ],
        gyv_supplier_order: [
            {
                label: "Anteprima",
                icon: "print",
                func: supplierOrderPreview
            }
        ],
        gyv_customer_offer: [
            {
                label: "Anteprima",
                icon: "print",
                func: customerOfferPreview
            },
            {
                label: "Converti in ordine",
                icon: "save",
                func: customerOfferConvertInOrder
            },
            {
                label: "Preventivo rifiutato",
                icon: "times",
                func: customerOfferDeclined
            }
        ],
    },
    ModalDetailsFooter: {
        sample: [
            {
                label: "Test",
                icon: "share",
                func: test
            }
        ],
        gyv_customer_order: [
            {
                label: "Anteprima",
                icon: "print",
                func: customerOrderPreview
            }
        ],
        gyv_supplier_order: [
            {
                label: "Anteprima",
                icon: "print",
                func: supplierOrderPreview
            }
        ],
        gyv_customer_offer: [
            {
                label: "Anteprima",
                icon: "print",
                func: customerOfferPreview
            },
            {
                label: "Converti in ordine",
                icon: "save",
                func: customerOfferConvertInOrder
            },
            {
                label: "Preventivo rifiutato",
                icon: "times",
                func: customerOfferDeclined
            }
        ]
    }
}

function test(item) {
    console.warn("test", item)
}

function customerOrderPreview(item, navigate) {
    navigate('/page/CustomerOrderPreview?id=' + item.id)
}

function customerOfferPreview(item, navigate) {
    navigate('/page/CustomerOfferPreview?id=' + item.id)
}

async function customerOfferConvertInOrder(item, navigate) {
    console.warn(item)
    const id = toast.loading("Conversione preventivo...")

    if(item.status && item.status.value === "10_CONFIRMED") {
        toast.update(id, { render: "Questo preventivo è già stato convertito in ordine.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
    }
    else {
        var bodyCustomer = {
            type: {value: '10_CONVERTED'}
        }
        var response1 = await DataManager.updateItem('customer', bodyCustomer, item.idCustomer)
        
    
        var body = {
            status: {value: '10_CONFIRMED'}
        }
        var response = await DataManager.updateItem('customer_offer', body, item.id)
        console.warn(response)
        if(response.success === 1) {
            toast.update(id, { render: "Preventivo confermato. Compila l'ordine con i campi mancanti. RICORDATI DI SALVARE L'ORDINE.", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
            navigate('/entity/customer_order/add', { 
                state: {
                    body: {
                        idCustomer: {id: item.idCustomer, name: item.customer},
                        idSalesAgent: item.idSalesAgent,
                        discount: item.discount,
                        notes: item.notes,
                        rows: item.rows,
                        files: item.files,
                        riferimento: item.riferimento,
                        status: {value: '0_PENDING'},
                        vat: item.vat,
                        idPaymentCondition: item.idPaymentCondition,
                        parking: item.parking,
                        parquet: item.parquet,
                        pedestrian_zone: item.pedestrian_zone,
                        hoist_elevator: item.hoist_elevator,
                        floor: item.floor,
                        no_traffic_zone: item.no_traffic_zone,
                        building: item.building,
                        zone: item.zone,
                        ladder_truck: item.ladder_truck,
                        elevator: item.elevator,
                        increase: item.increase,
                        discount: item.discount,
                        transportCost: item.transportCost,
                        deliveryDate: item.deliveryDate
                    }
                }
            })
            console.log(body);
        }
        else {
            if(response.message && response.message != '') {
                toast.update(id, { render: response.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
            }
            else {
                toast.update(id, { render: "Non è stato possibile convertire questo preventivo.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
            }
        }
    }
}

async function customerOfferDeclined(item, navigate) {
    const id = toast.loading("Annullamento preventivo...")
    var body = {
        status: {value: '20_DECLINED'}
    }
    var response = await DataManager.updateItem('customer_offer', body, item.id)
    console.warn(response)
    if(response.success === 1) {
        toast.update(id, { render: "Preventivo annullato con successo.", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
        navigate(0)
    }
    else {
        if(response.message && response.message != '') {
            toast.update(id, { render: response.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
        }
        else {
            toast.update(id, { render: "Non è stato possibile annullare questo preventivo.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
        }
    }
}

function supplierOrderPreview(item, navigate) {
    navigate('/page/SupplierOrderPreview?id=' + item.id)
}