import { ApiManager } from '../../core/api-manager';
import { DataManager } from '../../core/data-manager';
import { settings } from '../../config/settings';
import { LanguageProvider } from '../../core/language-provider';
import { toast } from 'react-toastify';
import { Gcal } from '../pages/GoogleCalendar/js/gcal';



export async function onInsert(response, entity, data) {

    if (entity === 'customer_offer') {
        /* inserisco internalCode progressivo : anno-counter */
        console.log(response, data)
        if (response.success === 1) {
            var body = {
                id: response.id
            }
            var resp = await ApiManager.sendAuthenticatedRequest('/custom/insert_offer_internal_code', body)
            console.log('onInsert', resp)
        }
    }

    if (entity === 'customer_order') {
        /* inserisco internalCode progressivo : anno-counter */
        console.log(response, data)
        if (response.success === 1) {
            var body = {
                id: response.id
            }
            var resp = await ApiManager.sendAuthenticatedRequest('/custom/insert_order_internal_code', body)
            console.log('onInsert', resp)

            /* var body = {
                status: {value: '10_CONFIRMED'}
            }
            var response = await DataManager.updateItem('customer_offer', body, item.id)
            console.warn(response) */
        }


    }
    if (entity === 'activity') {
        if (response.success === 1) {
            var loadCal = localStorage.getItem("GoogleCalendarDefaultCal") ? JSON.parse(localStorage.getItem("GoogleCalendarDefaultCal")) : null
            
            console.log(loadCal);
            if (loadCal) {
                var token = localStorage.getItem("GoogleAccessToken");

                var event = {
                    summary: data.title,
                    start: {
                        dateTime: new Date(data.plannedDate).toISOString(),
                        timeZone: "Europe/Paris",
                    },
                    end: {
                        dateTime: new Date(data.plannedDateEnd).toISOString(),
                        timeZone: "Europe/Paris",
                    }
                }


                var res = await Gcal.createEvent(token, event, loadCal.id)
                console.warn('create event', res)
                if (res.status === 'confirmed') {
                    var data = {
                        idGoogleCalendar: res.id
                    }
                    var responseUpdate = await DataManager.updateItem('activity', data, response.id);
                    console.log(responseUpdate, data, 'response:', res);
                    if (responseUpdate.success === 1) {
                        toast.success('Attività creata con successo.', { theme: 'colored' })
                    }
                }



            }else {
                //toast.error('Seleziona un calendario dalle impostazione prima di procedere (Sincronizza Google Calendar)', {theme: 'colored'})
            }
        }
    }
}