import { ApiManager } from '../../core/api-manager';
import { DataManager } from '../../core/data-manager';
import { settings } from '../../config/settings';
import { LanguageProvider } from '../../core/language-provider';
import { toast } from 'react-toastify';
import { Gcal } from '../pages/GoogleCalendar/js/gcal';



export async function onUpdate(response, entity, data, id) {
    if(entity === 'activity'){
        if(response.success === 1){
            var token = localStorage.getItem("GoogleAccessToken");
            var loadCal = localStorage.getItem("GoogleCalendarDefaultCal") ? JSON.parse(localStorage.getItem("GoogleCalendarDefaultCal")) : null;

            if(data.idGoogleCalendar && data.idGoogleCalendar.length > 2){
                var event = {
                    summary: data.title,
                    start: {
                        dateTime: new Date(data.plannedDate).toISOString(),
                        timeZone: "Europe/Paris",
                    },
                    end: {
                        dateTime: new Date(data.plannedDateEnd).toISOString(),
                        timeZone: "Europe/Paris",
                    }
                }
                var res = await Gcal.updateEvent(token, event, loadCal.id, data.idGoogleCalendar);


            }else {
                var event = {
                    summary: data.title,
                    start: {
                        dateTime: new Date (data.plannedDate).toISOString(),
                        timeZone: "Europe/Paris",
                    },
                    end: {
                        dateTime: new Date(data.plannedDateEnd).toISOString(),
                        timeZone: "Europe/Paris",
                    }
                }
                var res = await Gcal.createEvent(token, event, loadCal.id);
                if (res.status === 'confirmed') {
                    var body = {
                        idGoogleCalendar: res.id
                    }
                    var responseUpdate = await DataManager.updateItem('activity', body, id);                    
                }

            }

        }

    }
    
}