import { ApiManager } from '../../core/api-manager';
import { DataManager } from '../../core/data-manager';
import { settings } from '../../config/settings';
import { LanguageProvider } from '../../core/language-provider';
import { toast } from 'react-toastify';
import { Gcal } from '../pages/GoogleCalendar/js/gcal';

/*  var responseDelete = await DataManager.getItem('activity', id);
        console.log('delete', responseDelete); */

export async function onDelete(response, entity, id) {
    if (entity === 'activity') {

        if (response.success === 1) {
            var token = localStorage.getItem("GoogleAccessToken");
            var loadCal = localStorage.getItem("GoogleCalendarDefaultCal") ? JSON.parse(localStorage.getItem("GoogleCalendarDefaultCal")) : null;


            var body = {
                id: id
            }

            var responseDelete = await ApiManager.sendAuthenticatedRequest('/custom/delete_event', body);
            console.log('delete', responseDelete);
            if (responseDelete.success === 1) {
                if (responseDelete.body[0].idGoogleCalendar !== null) {
                    var res = await Gcal.deleteEvent(token, loadCal.id, responseDelete.body[0].idGoogleCalendar);
                    console.log('res delete', res)
                    toast.success('Attività eliminata anche da Google Calendar', { theme: 'colored' })
                }else {
                    toast.error('Questa attività non è presente nel Google Calendar', { theme: 'colored' })
                }
            }









        }

    }

}