import React, { useEffect, useState } from 'react';
import { Container, Button, Modal, Alert, Spinner, Image, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import XMLParser from 'react-xml-parser';
import { toast } from 'react-toastify';
import { DataManager } from '../../../core/data-manager';


export default function ImportXml3Cad({ data, entity, setDataKeyValue }) {

    const [showModal, setShowModal] = useState(false)
    const [uploading, setUploading] = useState(false);
    const [xmlContent, setXmlContent] = useState('');
    const [name, setName] = useState('');
    const [fornitori, setFornitori] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState('');


    function closeModal() {
        setShowModal(false)
    }

    function openModal() {
        setShowModal(true)
    }

    const onChange = async (e) => {
        e.preventDefault();
        var selected = e.target.files[0]
        setName(selected.name)
        let text = await blobToText(selected)
        setXmlContent(text)
    };

    const onChangeSupplier = (event) => {
        const response = event.target.value;
        setSelectedSupplier(response);
    }

    const blobToText = (blob) => {
        return new Promise((resolve, _) => {
            const fileReader = new FileReader();
            fileReader.readAsText(blob);
            fileReader.onloadend = function () {
                resolve(fileReader.result); // Here is the base64 string
            }
        });
    }

    async function getSupplier() {
        const sorting = {
            field: "name",
            order: "asc"
        }
        const response = await DataManager.getItems("supplier", 1, 50, sorting);
        console.log("fornitori: ", response.body);
        setFornitori(response.body)
    }

    useEffect(() => {
        getSupplier();
    }, []);




    async function uploadFile() {
        const id = toast.loading("Aggiorno righe ordine...")

        if (xmlContent !== '') {
            if (selectedSupplier != '') {
                setUploading(true)
                const json = new XMLParser().parseFromString(xmlContent);
                //console.log(json)
                var righe = json.getElementsByTagName('DETTAGLIO')
                console.log(righe);
                //console.log(righe)
                var temp = [...data.rows]
                if (righe) {
                    for (var i = 0; i < righe.length; i++) {
                        var riga = righe[i]
                        console.log(riga)
                        temp.push({
                            name: riga.children[2].value,
                            description: riga.children[5].value,
                            quantity: riga.children[3].value,
                            listPrice: (riga.children[10].value).replace(",", "."),
                            notes: riga.children[16].value,
                            idSupplier: fornitori[selectedSupplier],
                            price: ((riga.children[3].value) * ((riga.children[10].value).replace(",", ".")))
                        })
                    }
                }
                console.warn(temp)
                setDataKeyValue('rows', temp)
                setUploading(false)

                if (temp.length == 0) {
                    toast.update(id, { render: "Non è stata importata nessuna riga. Errore nel file.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
                }
                else {
                    toast.update(id, { render: "Righe importate con successo.", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
                }
                setXmlContent('')
                closeModal()
            }
            else {
                toast.update(id, { render: "Devi selezionare un fornitore prima di procedere.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });

            }


        }
        else {
            console.log('select a file...')
            toast.update(id, { render: "Non è stato possibile completare l'operazione.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
        }
    }



    return (
        <>
            <Button onClick={() => openModal()} variant='outline-secondary' size='sm'>Carica XML 3Cad</Button>

            <Modal show={showModal} onHide={closeModal} size="xs" scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Carica XML 3Cad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {uploading === false ? (
                        <>
                            <Container>
                                <input type="file" className='mb-3' onChange={onChange} />
                                <Form.Select aria-label="Default select example" onChange={onChangeSupplier} value={selectedSupplier}>
                                    <option value={''}>Seleziona il fornitore</option>
                                    {fornitori.map((item, index) => (
                                        <option value={index}>{item.name}</option>
                                    ))}

                                </Form.Select>
                            </Container>
                        </>
                    ) : (
                        <p className='lead'><FontAwesomeIcon icon="spinner" spin /></p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={uploadFile}>
                        Elabora
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};